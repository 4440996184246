<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
        ><i class="el-icon-refresh-right"></i> 刷新
        </el-button>
      </el-row>
      <search-table
          style="margin-top: 8px"
          ref="searchTable"
          :data="tableData"
          :tableRowKey="'id'"
          :columns="syncTableProperties"
          :totalPage="syncDataTotalPage"
          :need-fixed-height="true"
          @getTableData="getRequPush"
          v-loading="loading"
      >
        <template v-slot:operation="scope">
          <el-link class="vg_mr_8 vg_cursor" size="mini" type="primary" @click="goRequList(scope.row)">查看详情
          </el-link>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import {get} from '@api/request';
import SearchTable from "@/components/SearchTableV2.vue";
import {rqtaAPI} from "@api/modules/rqta";
import {userAPI} from "@api/modules/user";

export default {
  name: 'Push9009',
  components: {SearchTable},
  data() {
    return {
      tableData: [],
      loading: true,
      syncDataTotalPage: 0,
      syncTableProperties: [
        {prop: 'index', type: 'index', labelWidth: '50px', label: '序号'},
        {label: '报价单号', prop: 'quta_no', itemType: 'input', input: true, sortable: false, widthAuto: true},
        {label: '我司货号', prop: 'smpl_nos', itemType: 'input', input: true, sortable: false, widthAuto: true},
        {label: '报价人', prop: 'quta_quoter_name', itemType: 'input', input: false, sortable: false, widthAuto: true},
        {
          label: '退回人', prop: 'quta_pricer_name', itemType: 'select',
          options: [], input: true, sortable: false, widthAuto: true
        },
        {label: '退回原因', prop: 'backText', itemType: 'input', input: false, sortable: false, widthAuto: true},
        {label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true}
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getRequPush();
      this.getStff();
    },
    getStff() {
      get(userAPI.getAllUsersV1, {dept_ids: '10'})
          .then(res => {
            if (res.data.code === 0) {
              this.syncTableProperties[4].options = res.data.data.map(item => ({
                label: item.stff_name,
                value: item.stff_name
              }));
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //获取数据
    getRequPush() {
      let searchForm = this.$refs.searchTable.searchForm;
      get(rqtaAPI.getRqtaPushList, searchForm)
          .then(res => {
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              this.syncDataTotalPage = res.data.data.total;
              setTimeout(() => {
                this.loading = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //跳转RequList页面
    goRequList(row) {
      this.jump('/quta_edit', {
        perm_id: 123,
        form_id: row.quta_id
      })
    },
    // 刷新按钮
    buttonRefresh() {
      this.$refs.searchTable.resetFields();
      this.getRequPush();
      this.loading = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}

.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
